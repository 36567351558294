import axios from 'axios';
import config from 'config';
// @ts-ignore
import { marked } from 'marked';

const pageBuilderFields = `
  bikeName
  metaTitle
  metaDescription
  metaKeywords
  customColourFolder
  colourOptionName
  breadcrumbs{
    text
    link
  }
  productImageGallery {
    url
    uid
  }
  bikePageBuilder {
    ... on bikePageBuilder_textBlock_BlockType {
      id
      text
      heading
      typeHandle
    }
    ... on bikePageBuilder_imageLeftTextRight_BlockType {
      id
      text
      heading
      typeHandle
      textVerticalAlign
      image {
        url
      }
    }
    ... on bikePageBuilder_imageRightTextLeft_BlockType {
      id
      text
      heading
      typeHandle
      textVerticalAlign
      image {
        url
      }
    }
    ... on bikePageBuilder_sizeTableLeftTextRight_BlockType {
      id
      text
      heading
      sizeTable {
        size
        xs
        xxs
        sm
        md
        lg
        xl
        xxl
      }
      typeHandle
      textVerticalAlign
    }
    ... on bikePageBuilder_faqBlock_BlockType {
      id
      faqs {
        question
        answer
      }
      typeHandle
    }
    ... on bikePageBuilder_headingBlock_BlockType {
      id
      heading
      anchorLink
      typeHandle
    }
    ... on bikePageBuilder_anchorLinks_BlockType {
      id
      displayAnchorLinks
      typeHandle
    }
    ... on bikePageBuilder_imageVideoBlock_BlockType {
      id
      image {
        url
      }
      video {
        url
      }
      mediaType
      typeHandle
      imageText {
        ... on imageText_BlockType {
          id
          text
          image {
            url
          }
        }
      }
    }
    ... on bikePageBuilder_pressReviews_BlockType {
      id
      typeHandle
      reviews {
        ... on reviews_BlockType {
          id
          heading
          text
          image {
            url
          }
        }
      }
    }
  }
`;

function pageBuilderQuery(site, uri) {
  return {
    query: `
      query ($site: [String], $uri: [String]) {
        globalSets(handle: ["paymentInfo", "cycleToWork"]) {
          ... on paymentInfo_GlobalSet {
            handle
            body
          }
          ... on cycleToWork_GlobalSet {
            handle
            body
          }
        }
        bikesEntries(site: $site, uri: $uri) {
          ... on bikes_configurator_Entry {
            ${pageBuilderFields}
            children {
              ${pageBuilderFields}
            }
          }
        }
      }
    `,
    variables: {
      site,
      uri
    }
  };
}

export async function fetchCmsData(query, token = null) {
  const url = config.craftcmsUrl;
  const fullUrl = token ? url + `?token=${token}` : url;
  try {
    const res = await axios.post(fullUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with craft graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function parseMarkdown(data) {
  if (!data) return [];
  return data.bikePageBuilder.map((block) => {
    let newBlock = block;
    if (block.typeHandle === 'textBlock') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'imageLeftTextRight') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'imageRightTextLeft') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'sizeTableLeftTextRight') {
      newBlock.text = marked.parse(block.text);
    }
    return newBlock;
  });
}

export const craftBikeStore = {
  namespaced: true,
  state: {
    bikePage: null,
    selectedColour: null
  },
  mutations: {
    SET_BIKE_PAGE(state, bikeData) {
      state.bikePage = bikeData;
    },
    SET_SELECTED_COLOUR(state, colour) {
      state.selectedColour = colour;
    }
  },
  actions: {
    async updateBikePage({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const uri = payload.uri;
      const query = pageBuilderQuery(site, uri);
      try {
        const bikeData = await fetchCmsData(query, payload.token);
        const bikePage = parseMarkdown(bikeData.bikesEntries[0]);
        commit('SET_BIKE_PAGE', bikeData);
      } catch (error) {
        console.error(error);
      }
    },
    selectColour({ commit }, colour) {
      commit('SET_SELECTED_COLOUR', colour);
    }
  },
  getters: {
    getCraftBike: (state) =>
      state.bikePage && state.bikePage.bikesEntries
        ? state.bikePage.bikesEntries[0]
        : null,
    getCycleToWork: (state) => {
      const c2w =
        state.bikePage && state.bikePage.globalSets.length
          ? state.bikePage.globalSets.find((x) => x.handle === 'cycleToWork')
          : null;
      if (c2w) {
        c2w.body = marked.parse(c2w.body);
      }
      return c2w;
    },
    getPaymentInfo: (state) => {
      const paymentInfo =
        state.bikePage && state.bikePage.globalSets.length
          ? state.bikePage.globalSets.find((x) => x.handle === 'paymentInfo')
          : null;
      if (paymentInfo) {
        paymentInfo.body = marked.parse(paymentInfo.body);
      }
      return paymentInfo;
    },
    hasColourOptionContent: (state) =>
      !!(
        state.bikePage &&
        state.bikePage.bikesEntries.length &&
        state.bikePage.bikesEntries[0].children.length
      ),
    getBreadcrumbs: (state) => {
      if (!state.bikePage || !state.bikePage.bikesEntries.length) return [];
      const bike = state.bikePage.bikesEntries[0];
      return bike && bike.breadcrumbs ? bike.breadcrumbs : [];
    },
    getCraftBikeColourVariants: (state) => {
      if (!state.bikePage || !state.bikePage.bikesEntries.length) return [];
      const bike = state.bikePage.bikesEntries[0];
      return bike && bike.children ? bike.children : [];
    },
    getSelectedColour: (state) => state.selectedColour
  }
};
